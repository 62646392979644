import { graphql } from "../../../services/wp.api.service";

const query = `
query DownloadReportQuery($profileId: ID!, $regenerate: Boolean = true) {
	weqProfile(id: $profileId, idType: DATABASE_ID) {
	  reportUri(regenerate: $regenerate)
	}
}
`;

export const downloadReportQuery = (useQuery, variables, options = {}) => useQuery(
	["downloadReport", variables.profileId],
	() => graphql({
		query,
		variables
	}).
		then(({ data }) => {
			if (data.errors) {
				throw new Error(data.errors[0].message);
			}

			return data.data.weqProfile;
		}),
	{
		enabled: false,
		retry: false,
		staleTime: 10 * 60 * 1000,
		...options
	}
);
