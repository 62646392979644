import { graphql } from "../../services/wp.api.service";

const query = `
	query RespondentQuery($id: Int!) {
		respondentBy(respondentId: $id) {
			id: databaseId
			weqProfile: weqProfiles(first: 1) {
			  edges {
				node {
					databaseId
					profileResponses {
						response
						statement_id
					}
					weqProfileAcfFields {
						readInstructions
						profilestatus
					}
				}
			  }
			}
			respondentAcfFields {
			  consentprivacy
			  profilecomplete
			  name
			}
		  }
	}
`;

const respondentTransform = (respondent) => ({
	id: respondent.id,
	weqProfile: respondent.weqProfile?.edges
		? {
			...respondent.weqProfile.edges[0].node,
			...respondent.weqProfile.edges[0].node.weqProfileAcfFields
		}
		: null,
	...respondent.respondentAcfFields
});

const respondentQuery = (useQuery, id, options) => useQuery(
	["respondent", id],
	() => graphql({
		query,
		variables: {
			id
		}
	}).
		then((res) => respondentTransform(res.data.data.respondentBy)),
	{
		staleTime: 1000 * 60 * 10,
		...options
	}
);

export default respondentQuery;
