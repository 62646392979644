/* eslint-disable max-lines-per-function, max-statements, no-console, no-extra-parens */
/* eslint-disable react/jsx-no-bind */
import { Button, Col, Row, Spinner, Stack } from "react-bootstrap";
import { Element, scroller } from "react-scroll";
import { getProfileProgress, transformAnswers, transformResponsesToAnswers } from "../../helpers/respondents";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import AssessmentData from "../../liaStatements/statements_en.json";
import AssessmentQuestion from "./AssessmentQuestion";
import EqqoVideo from "./EqqoVideo";
import { difference } from "lodash";
import responsesMutation from "./responsesMutation";
import { useMutation } from "react-query";
import { useTimer } from "react-timer-hook";
import { useUser } from "../UserContext";

const Assessment = () => {
	const { id } = useParams();
	const { user, respondent } = useUser();
	const navigate = useNavigate();
	// Navigation vars
	const isFinalPage = useMemo(() => parseInt(id, 10) === Object.keys(AssessmentData.scenarios).length, [id]);
	const forwardUrl = useMemo(() => (isFinalPage ? "/report/landing" : `/profile/assessment/${Number(id) + 1}`), [id]);
	const buttonLabel = useMemo(() => (isFinalPage ? "Submit My Responses" : "Next"), [id]);
	// Assessment question loading and handling
	const scenarioData = useMemo(() => AssessmentData.scenarios[id], [id]);
	const { responseLabels } = AssessmentData;
	const questionArray = Object.entries(scenarioData.questions).map((question) => (
		{
			id: question[1].statement_id,
			response: null,
			text: question[1].statement
		}
	));
	const [answers, setAnswers] = useState(respondent?.weqProfile.profileResponses
		? transformResponsesToAnswers(respondent?.weqProfile.profileResponses, questionArray)
		: {});
	const [activeQuestion, setActiveQuestion] = useState(0);
	const [readScenario, setReadScenario] = useState(false);
	// Track if video is finished, i.e. has been watched
	const [videoFinished, setVideoFinished] = useState(false);
	// Control if questions can be answered or not (must watch video first)
	const [canAnswer, setCanAnswer] = useState(false);
	// Minimum time that needs to elapse before user can answer questions
	const minTime = 10000;
	const [timeoutMinTime, setTimeoutMinTime] = useState(false);
	const onVideoProgress = useCallback((progress) => {
		if (progress.played >= 0.99) {
			setVideoFinished(true);
		}
	}, []);
	// Handle submitting reponses to the backend
	const {
		isLoading,
		mutate
	} = responsesMutation(useMutation, {
		onError: (error) => {
			console.log(error);
		},
		onSuccess: ({ data }) => {
			const {
				profileResponses,
				weqProfileAcfFields: { profilestatus }
			} = data.data.updateWeqProfile.weqProfile;
			const newRespondent = {
				...respondent,
				weqProfile: {
					...respondent?.weqProfile,
					profileResponses,
					profileStatus: profilestatus
				},
				...getProfileProgress(profileResponses, AssessmentData.scenarios)
			};
			console.log(newRespondent);

			setActiveQuestion(0);
			setCanAnswer(false);
			setReadScenario(false);
			setVideoFinished(false);

			/**
			 * Should update respondent in UserContext
			 * setRespondent(newRespondent);
			 * addNotification({
			 * message: "Responses submitted successfully.",
			 * type: "success"
			 * });
			 */
			navigate(forwardUrl);
			scroller.scrollTo("scenario");
		}
	});
	const setNextActiveQuestion = (newAnswers) => {
		if (Object.values(newAnswers).length < questionArray.length) {
			let idx = 0;
			while (newAnswers[questionArray[idx]?.id]) {
				// eslint-disable-next-line no-plusplus
				idx++;
			}
			setActiveQuestion(idx);
		} else {
			setActiveQuestion(null);
		}
	};
	const updateQuestions = (value, { target }) => {
		const questionid = parseInt(target.name.split("-").pop(), 10);
		const newAnswers = {
			...answers,
			[questionid]: parseInt(value, 10)
		};
		setAnswers(newAnswers);
		setNextActiveQuestion(newAnswers);
	};
	const handleSubmit = useCallback(() => {
		const answeredQuestions = Object.values(answers).length;
		const totalQuestions = questionArray.length;

		if (answeredQuestions === totalQuestions) {
			const mutationData = {
				id: user.weqProfileId,
				profileResponses: transformAnswers(answers, user.weqProfileId)
			};
			mutate(mutationData);
		} else {
			const answeredIds = Object.keys(answers).map((key) => parseInt(key, 10));
			// eslint-disable-next-line camelcase
			const questionIds = Object.values(scenarioData.questions).map(({ statement_id }) => parseInt(statement_id, 10));
			const unansweredIds = difference(questionIds, answeredIds).sort();
			const [firstUnanswered] = unansweredIds;
			// Scroll to first unanswered question.
			scroller.scrollTo(`question-${firstUnanswered}`, {
				offset: -240
			});
		}
	});
	useTimer({
		autoStart: true,
		expiryTimestamp: new Date().getTime() + minTime,
		onExpire: () => {
			setTimeoutMinTime(true);
		}
	});
	useEffect(() => {
		if (readScenario) {
			scroller.scrollTo("video");
		}
	}, [readScenario]);
	useEffect(() => {
		if (canAnswer) {
			scroller.scrollTo("statements", {
				offset: -64
			});
		}
	}, [canAnswer]);

	return (
		<div className="assessment-view">
			<Row>
				<Element className="assessment-section-header" name="scenario">
					<h2 className="fs-24">1. About the Scenario</h2>
				</Element>
				<Col md={6}>
					<h3 className="fs-18 text-eqqo-dark-gray">Your Role</h3>
					<p className="fs-18 text-eqqo-dark-gray">{scenarioData.scenario.roles}</p>
				</Col>
				<Col>
					<h3 className="fs-18 text-eqqo-dark-gray">The Situation</h3>
					<p className="fs-18 text-eqqo-dark-gray">{scenarioData.scenario.situation}</p>
				</Col>
				<div className="assessment-actions">
					<Button
						className="btn-gradient w-100"
						onClick={() => {
							setReadScenario(true);
						}}
					>
						Continue <ArrowRightIcon height={20} width={20} />
					</Button>
				</div>
			</Row>
			<hr />
			<Row>
				<Element className={`assessment-section-header ${readScenario ? "" : "opacity-50 mb-0"}`} name="video">
					<h2 className="fs-24 text-primary">2. Video</h2>
					{ !readScenario && (
						<p className="fs-18 fw-semibold mb-0 text-eqqo-dark-gray">Watch Video After Reading About Scenario</p>
					)}
				</Element>
				<div className="video" data-video-id={scenarioData.videoId} hidden={!readScenario}>
					<EqqoVideo
						className="d-block w-100 mw-100 rounded-2 overflow-hidden"
						config={{
							wistia: {
								options: {
									playbackRateControl: false,
									playbar: process.env.REACT_APP_SHOW_SCROLL_BAR || false,
									playerColor: "000000",
									plugins: {
										"captions-v1": {
											onByDefault: true
										}
									},
									preload: true,
									videoFoam: true
								}
							}
						}}
						onProgress={onVideoProgress}
						style={{ aspectRatio: "16 / 9" }}
						url={`https://fast.wistia.net/embed/iframe/${scenarioData.videoId}`}
					/>
				</div>
				<div className="assessment-actions" hidden={!readScenario}>
					<Button
						className="btn-gradient w-100"
						disabled={!videoFinished || !timeoutMinTime}
						onClick={() => {
							setCanAnswer(true);
							scroller.scrollTo("statements");
						}}
					>
						I Have Watched the Video - Rate My Experience <ArrowRightIcon height={20} width={20} />
					</Button>
				</div>
			</Row>
			<hr />
			<Row>
				<Element className={`assessment-section-header ${canAnswer ? "" : "opacity-50 mb-0"}`} name="statements">
					<h2 className="fs-24 text-primary">3. Rate Each Statement</h2>
					{ !canAnswer && (
						<p className="fs-18 fw-semibold mb-0 text-eqqo-dark-gray">Rate Experience After Watching Video</p>
					)}
				</Element>
				<Stack
					hidden={!canAnswer}
					style={{
						gap: "2rem",
						paddingInline: "0 !important"
					}}>
					{questionArray.map((ques, idx) => (
						<Element key={ques.id} name={`question-${ques.id}`}>
							<AssessmentQuestion
								active={idx === activeQuestion}
								answer={answers[ques.id]}
								disabled={!canAnswer}
								key={ques.id}
								question={ques}
								responseLabels={responseLabels}
								updateQuestions={updateQuestions}
							/>
						</Element>
					))}
				</Stack>
			</Row>
			<hr hidden={!canAnswer} />
			<div className="assessment-actions" hidden={!canAnswer}>
				<Button
					className="btn-gradient w-100"
					disabled={isLoading}
					onClick={handleSubmit}
					type="submit"
					variant="primary"
				>
					{
						isLoading
							? <Spinner animation="border" size="sm" />
							: <> {buttonLabel} <ArrowRightIcon height={20} width={20} /></>
					}
				</Button>
			</div>
		</div>
	);
};

export default Assessment;
