/* eslint-disable max-len */

const InstructionsText = () => (
	<div>
		<p>
			Thank you for choosing to take this WE-I In Action Profile. We hope you find it to be a useful learning process.
			Instructions for completing this tool are described below. You DO NOT need to be familiar with emotional
			intelligence to take this tool.
		</p>

		<h4 className="text-blue fw-bold mt-4 mb-3">
			How This Tool Works
		</h4>

		<p>
			This tool places you in a series of situations (by watching 8 video segments) and then asks you to report your
			experience as you watched the videos, whatever that experience is. It is critical that you put yourself into the
			situation as if it were real and actually happening to you in the moment.
		</p>

		<p className="fw-bold">
			Your task is to:
		</p>

		<ul>
			<li>
				Be aware of and track your experience as best you can
				{" "}

				<strong>
					<u>
						as if this is really happening to you NOW.
					</u>
				</strong>
			</li>

			<li>
				Be aware of what you think the person talking to you is experiencing.
			</li>
		</ul>

		<p>
			You will then report your own experience and what you believe the other person is experiencing. This tool does NOT assess whether you are a good, skilled, or knowledgeable person! The goal is to be authentic in your responses, without censorship.
		</p>

		<h4 className="text-blue fw-bold mt-4 mb-3">
			Instructions
		</h4>

		<p>
			<span className="fw-bold">
				Timing.
			</span>

			{" Allow up to forty-five minutes to complete this tool."}
		</p>

		<p>
			<span className="fw-bold">
				Demographic Data.
			</span>

			{" Before getting started, you may be asked to submit some demographic. information. If this is requested, please note that some fields are required. We request that you complete all fields."}
		</p>

		<p>
			<span className="fw-bold">
				Taking the Assessment.
			</span>

			{" After filling in your demographic data you will be ready to take the tool."}
		</p>

		<ul>
			<li>
				<span className="fw-bold">
					Navigation:
				</span>

				{" At the top of your screen, eight segments will appear, numbered 1 through 8. There are two rows. The top row is labeled &quot;Watch&quot; and the row directly beneath it is labeled &quot;Rate&quot;."}
			</li>

			<li>
				<span className="fw-bold">
					Watch a Video:
				</span>

				{" "}
				Begin by clicking &quot;Segment #1&quot; in the Watch row to watch the first video. We encourage you to watch it two times.
			</li>

			<li>
				<span className="fw-bold">
					Rating Form:
				</span>

				{" Then, to bring up the rating form, look toward the top of the screen again. If the Rate row is present, click on 'Rating' in the Rate row to bring up the form where you can rate the &quot;Segment #1&quot; video. Otherwise, look in the upper right hand corner of the video segment window, and click on the link: &quot;Click here to rate your experience after watching this video&quot;. The rating form will display."}
			</li>

			<li>
				<span className="fw-bold">
					Submit Ratings:
				</span>

				{" Rate each statement on the form and then click "}

				<span className="fw-bold">
					Submit Responses
				</span>

				{" at the bottom of the page. If you missed any ratings, the rating form will re-display, and the system will highlight the missed items."}
			</li>

			<li>
				<span className="fw-bold">
					Finalize Segment:
				</span>

				{" When all items are complete, then the segment MUST be "}

				<u>
					finalized.
				</u>

				{" Please review your ratings and make changes if desired. When you are satisfied, click on the "}

				<span className="fw-bold">
					Finalize Segment Responses
				</span>

				{" checkbox at the bottom of the page and then click "}

				<span className="fw-bold">
					Submit Responses.
				</span>
			</li>
		</ul>

		<p>
			<span className="fw-bold">
				State of mind.
			</span>

			{" It is important to clear your mind and surroundings of distractions. Move in close, lean in to listen. Watch each segment twice. Then rate the words and statements listed for each video on a 1-5 scale. Some of the words or statements will have NO FIT for you. Rate these as 1. Others might be a total fit. Rate those 5. The rating scale is provided on each rating form."}
		</p>

		<p>
			Do this thoughtfully but quickly. Go with your first reaction...what rings true for you. You may or may not have thought the specific
			words that are listed. The key measure is how closely the statement fits your experience.
		</p>

		<p>
			<span className="fw-bold">
				When finsished.
			</span>

			{" When you have finished viewing the 8 videos and completing the 8 rating pages, the tool will tell you it is complete. Then, simply log out."}
		</p>
	</div>
);

export default InstructionsText;
