/* eslint-disable react/no-adjacent-inline-elements */
import { Alert, Button, Modal, Spinner } from "react-bootstrap";
import { useQuery, useQueryClient } from "react-query";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import PropTypes from "prop-types";

import { downloadReportQuery } from "./downloadReportQuery";


const DownloadReportModal = ({
	name,
	onHide,
	profileId,
	show
}) => {
	const queryClient = useQueryClient();

	const {
		error,
		isError,
		isLoading,
		data: profile
	} = downloadReportQuery(
		useQuery,
		{ profileId },
		{
			enabled: show,
			onSuccess: () => {
				queryClient.invalidateQueries("groupRespondents");
				queryClient.invalidateQueries("respondent");
			}
		}
	);

	return (
		<Modal centered onHide={onHide} show={show}>
			<Modal.Header closeButton>
				Generate Profile Report
			</Modal.Header>
			<Modal.Body className="p-5 text-center">
				{name ? <h1 className="mb-3">{name}</h1> : null}
				{
					isLoading
						? (<div>
							<p className="text-success"><strong>Generating Report PDF</strong><br />Please wait...</p>
							<Spinner animation="border" variant="success" />
						</div>)
						: isError
							? <Alert variant="danger">{error.toString()}</Alert>
							: profile?.reportUri
								? (
									<Button
										className="mb-5"
										href={profile.reportUri}
										rel="noreferrer"
										size="lg"
										target="_blank"
									>
										<ArrowDownTrayIcon className="me-2" />
										Download Report
									</Button>
								)
								: null
				}
			</Modal.Body>
		</Modal>
	);
};

DownloadReportModal.propTypes = {
	name: PropTypes.string,
	onHide: PropTypes.func.isRequired,
	profileId: PropTypes.number.isRequired,
	show: PropTypes.bool
};

DownloadReportModal.defaultProps = {
	name: null,
	show: false
};

export default DownloadReportModal;
