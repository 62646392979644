/* eslint-disable max-len, max-statements, no-unused-vars, no-console */
import * as yup from "yup";
import { Accordion, Button, Container, Form } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import AssessmentData from "../../liaStatements/statements_en.json";
import EqqoVideo from "./EqqoVideo";
import { Formik } from "formik";
import InstructionsText from "./InstructionsText";
import { getProfileProgress } from "../../helpers/respondents";
import { updateWeqProfileMutation } from "../../services/wp.weqprofile.mutation";
import { useUser } from "../UserContext";

const Instructions = () => {
	const navigate = useNavigate();
	const { user, userProfile, respondent } = useUser();
	const instructionVideoID = "r7sojqvth9";
	// Track if video is finished, i.e. has been watched
	const [videoFinished, setVideoFinished] = useState(false);
	const onProgress = useCallback((progress) => {
		if (progress.played >= 0.99) {
			setVideoFinished(true);
		}
	}, []);

	const progress = useMemo(() => ({ ...getProfileProgress(respondent?.weqProfile?.profileResponses || [], AssessmentData.scenarios) }), [respondent]);

	const queryClient = useQueryClient();
	const {
		mutateAsync: updateProfile
	} = updateWeqProfileMutation(useMutation);

	const handleFormSubmit = useCallback(async (values) => {
		const input = {
			id: user.weqProfileId,
			weqProfileAcfFields: {
				profilestatus: "inProgress",
				readInstructions: !!values.readInstructions.length
			}
		};

		try {
			await updateProfile(input);
			await queryClient.refetchQueries(["respondent", user.databaseId]);
			navigate(`/profile/assessment/${progress.nextScenario}`);
		} catch (error) {
			console.log(error);
		}
	});
	const requiredMessage = "Please acknowledge that you understand these instructions.";
	const validationSchema = yup.object().shape({
		readInstructions: yup.array(yup.string("read"), requiredMessage).length(1, requiredMessage).
			required(requiredMessage)
	});

	return (
		<div className="profile__instructions">
			<h2>Hi {userProfile?.firstName}! Please watch the video for instructions.</h2>
			<Container className="mt-4 video px-0">
				<EqqoVideo
					className="d-block w-100 mw-100"
					config={{
						wistia: {
							options: {
								playbackRateControl: false,
								playbar: process.env.REACT_APP_SHOW_SCROLL_BAR || false,
								playerColor: "000000",
								plugins: {
									"captions-v1": {
										onByDefault: true
									}
								},
								preload: true,
								videoFoam: true
							}
						}
					}}
					onProgress={onProgress}
					style={{ aspectRatio: "16 / 9" }}
					url={`https://fast.wistia.net/embed/iframe/${instructionVideoID}`}
				/>
			</Container>
			<Accordion className="mt-4" defaultActiveKey="0">
				<Accordion.Item eventKey="1">
					<Accordion.Header>Read the Instructions</Accordion.Header>
					<Accordion.Body>
						<InstructionsText />
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			<Formik
				initialValues={{ readInstructions: [] }}
				onSubmit={handleFormSubmit}
				validationSchema={validationSchema}
			>
				{({
					errors,
					handleBlur,
					handleChange,
					handleSubmit,
					touched,
					values
				}) => (
					<Form className="my-4" onSubmit={handleSubmit}>
						<Form.Group className="container my-3" controlId="readInstructions">
							<Form.Check.Input
								isInvalid={touched.readInstructions && errors.readInstructions}
								name="readInstructions"
								onBlur={handleBlur}
								onChange={handleChange}
								value="read"
							/>
							<Form.Check.Label className="d-inline mx-2">
								{/* eslint-disable-next-line max-len */}
								I have watched and/or read the instructions and I understand that I am to respond not in my current professional role, but in the roles suggested before each video.
							</Form.Check.Label>
							{errors.readInstructions && (
								<Form.Control.Feedback
									className="my-2"
									type="invalid"
								>
									{errors.readInstructions}
								</Form.Control.Feedback>)}
						</Form.Group>
						<div className="actions text-center my-4">
							{respondent?.weqProfile && !respondent.weqProfile.profileCompleted && !respondent.weqProfile.readInstructions && (
								<Button
									className="btn-gradient w-100"
									disabled={errors.readInstructions}
									type="submit"
								>
									Start Assessment <ArrowRightIcon height={24} width={24} />
								</Button>
							)}
							{respondent?.weqProfile && !respondent.weqProfile.profileCompleted && respondent.weqProfile.readInstructions && (
								<NavLink className="btn btn-gradient w-100" to={`/profile/assessment/${progress.nextScenario}`}>
									Continue Profile <ArrowRightIcon height={24} width={24} />
								</NavLink>
							)}
						</div>
					</Form>)}
			</Formik>
		</div>
	);
};

export default Instructions;
