/* eslint-disable lines-around-comment, no-console, newline-per-chained-call */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars, id-length */
import * as yup from "yup";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import { camelCase, mapKeys } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import EqqoFormField from "../EqqoFormField";
import { Formik } from "formik";
import form from "../../components/sign-up-form.json";
import saveUserProfile from "./saveProfileMutation";
import { useAuth } from "../services/eqqo.auth.service";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

const QuestionnaireForm = () => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const validationSchema = useMemo(() => {
		const schema = {};
		form.fields.forEach((field) => {
			let validFunction = null;
			switch (field.type) {
			case "checkbox":
				validFunction = yup.array().min(1).of(yup.string().required()).required();
				break;
			case "select":
				validFunction = yup.string().required("Question is Required");
				break;
			default:
				validFunction = yup.string();

				if (field.required) {
					validFunction = validFunction.required("Question is Required");
				}
			}
			schema[field.name] = validFunction;
		});
		return schema;
	}, []);

	const setInitialValues = useMemo(() => {
		const values = {};
		form.fields.forEach((field) => {
			values[field.name] = "";
		});
		return values;
	}, []);

	const fieldCols = (width) => parseInt(12 * width / 100, 10);
	const queryClient = useQueryClient();
	const {
		isLoading,
		mutateAsync
	} = saveUserProfile(
		useMutation,
		user.databaseId,
		{
			onSuccess: async () => {
				console.log("Mutation Success");
				await queryClient.refetchQueries("userProfile");
				await queryClient.refetchQueries(["auth-user"]);

				navigate("/sign-up/pay");
			}
		}
	);
	return (
		<Formik
			initialValues={setInitialValues}
			onSubmit={(values, actions) => {
				setTimeout(() => {
					/* Convert form field names from ACF to camel case for graphQL */
					const submitValues = mapKeys(values, (v, k) => camelCase(k));
					/* Console.log(JSON.stringify(submitValues, null, 2)); */
					mutateAsync({ userProfile: submitValues });
				}, 500);
			}}
			validationSchema={yup.object(validationSchema)}
		>
			{(formik) => (
				<Form className="flow row" name={form.title} noValidate onSubmit={formik.handleSubmit}>
					<h2>Required</h2>
					{form.fields.map((field) => (
						<Col className={"align-content-center"} key={field.key} md={fieldCols(field.wrapper.width || 100)}>
							<EqqoFormField
								field={field}
								isInvalid={Boolean(formik.errors[field.name]) && formik.touched[field.name]}
								name={field.name}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
							/>
						</Col>
					))}
					<Button
						className="btn-gradient w-100"
						disabled={isLoading}
						onClick={formik.handleSubmit}
						type="submit"
						variant="primary"
					>
						{
							isLoading
								? <Spinner animation="border" size="sm" />
								: <>Continue <ArrowRightIcon height={20} width={20} /></>
						}
					</Button>
					{/* <Button className="btn-gradient w-100" type="submit" variant="primary">Continue <ArrowRightIcon height={20} width={20} /></Button> */}
				</Form>
			)}
		</Formik>
	);
};

export default QuestionnaireForm;
