import { Table } from "react-bootstrap";

const PaymentHistory = () => (
	<div>
		<h2>Payment History</h2>
		<Table className="payments-table">
			<thead>
				<th>Date</th>
				<th>Amount</th>
			</thead>
			<tbody>
				<tr>
					<td>11/15/2024</td>
					<td>$195</td>
				</tr>
			</tbody>
		</Table>
	</div>
);

export default PaymentHistory;
