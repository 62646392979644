/* eslint-disable max-len, no-console */
/* eslint-disable react/no-unescaped-entities */
import * as yup from "yup";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import Helmet from "react-helmet";
import SignUpStepIndicator from "./SignUpStepIndicator";
import { useAuth } from "../services/eqqo.auth.service";

const SignUp = () => {
	const navigate = useNavigate();
	const [registrationError, setRegistrationError] = useState(null);
	const initialValues = {
		email: "",
		password: ""
	};
	const { register, user } = useAuth();

	const validationSchema = yup.object({
		email: yup.string().email("Invalid email. Use format alex@example.com").
			required("Email address is required"),
		password: yup.string().
			required("Password is required").
			min(8, "Password must be at least 8 characters")
	});

	const handleFormSubmit = useCallback((values, actions) => {
		setRegistrationError(null);
		register(values).
			catch((error) => {
				setRegistrationError(error.message);
			}).
			finally(() => {
				actions.setSubmitting(false);
			});
	});

	// User should become defined after registration
	useEffect(() => {
		if (user) {
			navigate("/sign-in/confirm");
		}
	}, [user]);

	return (
		<Row as="main" className="sign-up">
			<Helmet>
				<title>
					{"Create Your EQQO Account"}
				</title>
			</Helmet>

			<Col className="create-account flow" fluid="true">
				<img
					alt="LOGO"
					className="logo"
					height="152"
					src="/eqqo_logo.png"
					width="472"
				/>
				<Container className="bg-white">
					<SignUpStepIndicator current={1} />
					<h1 className="my-4">Create Your EQQO Account</h1>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam.
					</p>
					{registrationError &&
						<div className="alert alert-danger my-3" dangerouslySetInnerHTML={{ __html: `${registrationError.slice(7)}` }} /> }
					<Formik
						initialValues={initialValues}
						onSubmit={handleFormSubmit}
						validationSchema={validationSchema}
					>
						{({
							errors,
							handleBlur,
							handleChange,
							handleSubmit,
							isSubmitting,
							touched,
							values
						}) => (
							<Form className="mt-5" noValidate onSubmit={handleSubmit}>
								<Form.Label>Email Address</Form.Label>
								<Form.Control
									isInvalid={Boolean(errors.email)}
									name="email"
									onBlur={handleBlur}
									onChange={handleChange}
									placeholder="alex@example.com"
									required
									type="email"
									value={values.email}
								/>
								<Form.Control.Feedback type="invalid">
									{touched.email && errors.email ? <p>{errors.email}</p> : null}
								</Form.Control.Feedback>
								<Form.Label className="mt-4">Password</Form.Label>
								<Form.Control
									isInvalid={Boolean(errors.password)}
									name="password"
									onBlur={handleBlur}
									onChange={handleChange}
									required
									type="password"
									value={values.password}
								/>
								<Form.Control.Feedback type="invalid">
									{touched.password && errors.password ? <p>{errors.password}</p> : null}
								</Form.Control.Feedback>
								<Button className="btn-gradient mt-4 w-100 justify-content-center text-uppercase" type="submit" variant="primary">
									{isSubmitting ? <Spinner animation="border" size="sm" /> : "Create Account"}
								</Button>
							</Form>)}
					</Formik>
					<p className="labeled-divider">OR</p>
					<Button className="justify-content-center text-uppercase w-100" variant="outline btn-outline-eqqo-purple">
						Sign Up with Google
					</Button>
					<p className="my-4">By creating an account, you agree to EQQO's Privacy Policy & Terms of Use</p>
					<p className="mt-5 mb-2">Already have an account?</p>
					<NavLink to="/sign-in">Sign In</NavLink>
				</Container>
			</Col>
		</Row>
	);
};

export default SignUp;
