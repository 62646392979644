import { useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

import DownloadReportModal from "./DownloadReportModal";

const DownloadReportButton = ({
	children,
	className,
	name,
	pdfUrl,
	profileId,
	variant
}) => {
	const [show, setShow] = useState(false);

	const toggleShow = useCallback(() => {
		if (pdfUrl) {
			window.open(pdfUrl, "_blank");
		} else {
			setShow((cur) => !cur);
		}
	}, [pdfUrl]);

	return (
		<>
			<Button className={className} onClick={toggleShow} variant={variant}>
				{children}
			</Button>
			<DownloadReportModal
				name={name}
				onHide={toggleShow}
				profileId={profileId}
				show={show}
			/>
		</>
	);
};

DownloadReportButton.propTypes = {
	children: PropTypes.element.isRequired,
	className: PropTypes.string,
	name: PropTypes.string,
	pdfUrl: PropTypes.string,
	profileId: PropTypes.number.isRequired,
	variant: PropTypes.string
};

DownloadReportButton.defaultProps = {
	className: null,
	name: null,
	pdfUrl: null,
	variant: "primary"
};

export default DownloadReportButton;
