import { ArrowDownTrayIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { Button, Col, Image, Row } from "react-bootstrap";
import Helmet from "react-helmet";

import DownloadReportButton from "../pages/practitioner/components/DownloadReportButton";
import { useUser } from "./UserContext";

import wave from "../assets/eqqo-wave.png";

const usePdf = process.env.REACT_APP_PDF_REPORT;

const ReportLanding = () => {
	const { user } = useUser();
	return (
		<Row as="main" className="profile profile__landing | bg-white flex-dir-col pt-4">
			<Helmet>
				<title>
					{"EQQO Profile Report"}
				</title>
			</Helmet>
			<Col className="align-items-center d-flex flex-column gap-4">
				<Image className="mt-4" roundedCircle src={wave} />
				<h1 className="fw-bolder px-4 text-center">Your EQQO Profile Report</h1>
				<p className="gradient-text my-4 text-center">Emotional Quotient Questionnaire & Overview Profile</p>
				{
					usePdf
						? (
							<DownloadReportButton
								name={`${user?.firstName} ${user?.lastName}`}
								pdfUrl={user?.weqProfilePdfUrl}
								profileId={user?.weqProfileId}
							>
								<ArrowDownTrayIcon className="i" /> Download Report
							</DownloadReportButton>
						)
						: (
							<Button
								className="mt-4 rounded-pill"
								href="/report"
								variant="gradient"
							>View Your Report <ArrowRightIcon height={24} width={24} /></Button>
						)
				}
			</Col>
		</Row>
	);
};

export default ReportLanding;
