/* eslint-disable  */
import { createContext, useCallback, useContext, useEffect } from "react";
import { useAuth } from "./services/eqqo.auth.service";
import profileQuery from "./profile/profileQuery";
import respondentQuery from "./profile/respondentQuery";
import { useQuery } from "react-query";
// import { Modal } from "react-bootstrap";
// import { useNotifications } from "../components/NotificationsContext";
// import { useState } from "react";

const UserContext = createContext(null);

// eslint-disable-next-line react/prop-types
export const UserContextProvider = ({ children }) => {
	const { user } = useAuth();
	const { data:userProfile } = profileQuery(useQuery, user?.databaseId, { enabled: Boolean(user) });
	const { data:respondent } = respondentQuery(useQuery, user?.respondentId, { enabled: Boolean(user) });
	// I need to be able to update respondent from the Assessment component, but can't get useState to work here.
	// const [respondent, setRespondent] = useState(() => {
	// 	return respondentData;
	// });
	// Example of how to display notifications via UserContext
	// const [ modalOpen, setModalOpen ] = useState(false);
	// const { addNotification } = useNotifications();
	// const closeModal = useCallback(() => {
	// 	setModalOpen(false);
	// }, [setModalOpen]);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		addNotification({message:"test"});
	// 		setModalOpen(true);
	// 	}, 3500);
	// }, []);

	return (
		<UserContext.Provider value={{
			respondent,
			user,
			userProfile,
		}}>
			{children}
			{/* <Modal show={modalOpen} onHide={closeModal} >
				<Modal.Header closeButton>
					Hey
				</Modal.Header>
				<Modal.Body>
					Are you still here?
				</Modal.Body>
			</Modal> */}
		</UserContext.Provider>
	);
};

export const useUser = () => useContext(UserContext);
